import type { api } from '@meterup/proto';
import { observer, useCommand, useRegisterCommands } from '@meterup/command';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { fetchCompaniesJSON } from '../api/company_api';
import { paths } from '../constants';
import { makeDrawerLink } from '../utils/makeLink';

const empty: api.CompanyResponse[] = [];

export const AppLayout = observer(({ children }: React.PropsWithChildren<{}>) => {
  const companies =
    useQuery(['companies', 'filter', 'all'], () => fetchCompaniesJSON()).data ?? empty;

  const { state } = useCommand();

  const navigate = useNavigate();

  useRegisterCommands(
    companies
      ? [
          state.nodeFactory.directory({
            id: 'global-companies-search',
            display: 'Go to company…',
            label: 'Go to company…',
            shortcut: '$mod+/',
            children: companies.map((company) =>
              state.nodeFactory.action({
                id: company.sid,
                display: `${company.name} (${company.slug})`,
                label: `${company.name} (${company.slug})`,
                synonyms: company.slug,
                icon: 'company',
                onSelect() {
                  navigate(
                    makeDrawerLink(paths.drawers.CompanySummary, { companyName: company.slug }),
                  );
                },
              }),
            ),
          }),
        ]
      : [],
    [companies],
  );

  return children;
});
