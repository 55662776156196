/* eslint-disable react/prop-types */
import type { IconName } from '@meterup/atto';
import type { LinkProps } from 'react-router-dom';
import type {
  Accessor,
  Cell,
  Column,
  IdType,
  Row,
  TableCellProps,
  TableRowProps,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
} from 'react-table';
import Fuzzy from '@leeoniya/ufuzzy';
import {
  activeThemeClassName,
  Alert,
  Button,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuGroup,
  DropdownMenuPopover,
  PaneContent,
  PaneHeader,
  TextInput,
} from '@meterup/atto';
import { isDefined } from '@meterup/common';
// TODO figure out why export-to-csv doesn't support esm import without explicit path
// or upgrade export-to-csv to version 2
import { ExportToCsv } from 'export-to-csv/build/export-to-csv';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useGlobalFilter, useSortBy, useTable } from 'react-table';

import {
  NavigableTableArrowCell,
  NavigableTableDeselectCell,
  NavigableTablePlaceholderCell,
} from '../Table/NavigableTable';
import {
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../Table/Table';

export type CSVRowFormatter<D extends object> = (
  row: D,
  rowIndex: number,
) => Record<string, unknown> | Record<string, unknown>[];

function createDefaultCSVRowFormatter<D extends object>(
  columns: readonly Column<D>[],
): CSVRowFormatter<D> {
  return (row: D, rowIndex: number) =>
    Object.fromEntries(
      columns.map((column) => [
        column.Header,
        typeof column.accessor === 'function'
          ? (column.accessor as Accessor<D>)(row, rowIndex, { subRows: [], depth: 0, data: [] })
          : row[column.accessor as keyof D],
      ]),
    );
}

function createCSVExportHandler<D extends object>(
  csvRowFormatter: CSVRowFormatter<D>,
  data: readonly D[],
  exportFileName: string,
) {
  const dataToExport = data.map(csvRowFormatter).flat();

  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    filename: exportFileName.replace('.csv', ''),
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };

  const csvExporter = new ExportToCsv(options);

  return () => csvExporter.generateCsv(dataToExport);
}

type GlobalSearchProps = {
  instanceProps: UseGlobalFiltersInstanceProps<any> & { state: UseGlobalFiltersState<any> };
};

function GlobalSearch({ instanceProps }: GlobalSearchProps) {
  return (
    <TextInput
      aria-label="Search"
      type="search"
      id="search"
      icon="search-scoped"
      value={instanceProps.state.globalFilter ?? ''}
      onChange={(e) => {
        instanceProps.setGlobalFilter(e);
      }}
    />
  );
}

const uf = new Fuzzy({
  intraChars: '[-a-z._:\\d]',
});

const DOTS_DASHES_COLONS_UNDERSCORES = /[.\-_:]/g;

function globalFilterFn<D extends object = {}>(
  rows: Array<Row<D>>,
  columnIds: Array<IdType<D>>,
  filterValue: string,
): Array<Row<D>> {
  // make dots, dashes, colons, and underscores interchangeable
  const pattern = filterValue.replaceAll(DOTS_DASHES_COLONS_UNDERSCORES, '');

  if (!pattern) return rows;

  const indexes = uf.filter(
    rows.map((row) =>
      columnIds
        .map((columnId) => String(row.values[columnId]))
        .join(' ')
        .replaceAll(DOTS_DASHES_COLONS_UNDERSCORES, ''),
    ),
    pattern,
  );
  if (!indexes) return [];

  return indexes.map((i) => rows[i]);
}

interface AutoTableRowProps<D extends object = {}> {
  linkProps?: (row: D) => LinkProps;
  isRowSelected?: (row: D) => boolean;
  onRowDeselect?: (row: D) => void;
}

export interface Table2Props<D extends object = {}> extends AutoTableRowProps<D> {
  columns: ReadonlyArray<Column<D>>;
  data: readonly D[];
  tabs?: React.ReactNode;
  icon?: IconName;
  heading?: React.ReactNode;
  emptyStateHeading?: string;
  emptyStateCopy?: string;
  additionalDropdownItems?: React.ReactNode;
  additionalControls?: React.ReactNode;
  shouldShowTopBar?: boolean;
  csvFileName?: string;
  csvRowFormatter?: CSVRowFormatter<D>;
}

function AutoTableCellImpl<D extends object>({
  cell,
  ...props
}: { cell: Cell<D> } & TableCellProps) {
  return <TableDataCell {...props}>{cell.render('Cell')}</TableDataCell>;
}

const AutoTableCell = React.memo(AutoTableCellImpl) as typeof AutoTableCellImpl;

function AutoTableRowImpl<D extends object>({
  row,
  linkProps,
  isRowSelected,
  onRowDeselect,
  ...tableRowProps
}: {
  row: Row<D>;
} & AutoTableRowProps<D> &
  TableRowProps) {
  const isNavigableTable = isDefined(linkProps);
  const SmartLinkOrDiv = isNavigableTable ? Link : 'div';
  const maybeLinkProps = isDefined(linkProps) ? linkProps(row.original) : {};

  const rowIsSelected = isDefined(isRowSelected) ? isRowSelected(row.original) : false;

  return (
    <TableRow
      {...tableRowProps}
      {...maybeLinkProps}
      as={SmartLinkOrDiv}
      isSelected={rowIsSelected}
      className={rowIsSelected && activeThemeClassName}
    >
      {row.cells.map((cell) => (
        <AutoTableCell {...cell.getCellProps()} cell={cell} />
      ))}
      {rowIsSelected && onRowDeselect ? (
        <NavigableTableDeselectCell onClick={() => onRowDeselect(row.original)} />
      ) : (
        isNavigableTable && <NavigableTableArrowCell />
      )}
    </TableRow>
  );
}

const AutoTableRow = React.memo(AutoTableRowImpl) as typeof AutoTableRowImpl;

export function AutoTable<D extends object>({
  columns,
  data,
  linkProps,
  isRowSelected,
  onRowDeselect,
  icon,
  heading,
  tabs,
  shouldShowTopBar = true,
  csvFileName = 'export.csv',
  csvRowFormatter,
  emptyStateHeading = 'No rows',
  emptyStateCopy,
  additionalDropdownItems,
  additionalControls,
}: Table2Props<D>) {
  const tableInstance = useTable(
    {
      columns,
      data,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      globalFilter: globalFilterFn<D>,
    },
    useGlobalFilter,
    useSortBy,
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows } = tableInstance;

  const isNavigableTable = isDefined(linkProps);

  const exportHandler = useMemo(
    () =>
      createCSVExportHandler(
        csvRowFormatter ?? createDefaultCSVRowFormatter(columns),
        data,
        csvFileName,
      ),
    [csvRowFormatter, csvFileName, columns, data],
  );

  return (
    <>
      {shouldShowTopBar && (
        <PaneHeader
          icon={icon}
          heading={heading}
          oldTabs={tabs}
          actions={
            <>
              {additionalControls}
              <Button
                icon="download"
                arrangement="leading-icon"
                variant="secondary"
                onClick={exportHandler}
              >
                Export
              </Button>
              <GlobalSearch instanceProps={tableInstance} />
              {additionalDropdownItems && (
                <DropdownMenu>
                  <DropdownMenuButton
                    arrangement="hidden-label"
                    icon="overflow-vertical"
                    variant="secondary"
                  >
                    Actions
                  </DropdownMenuButton>
                  <DropdownMenuPopover>
                    <DropdownMenuGroup>{additionalDropdownItems}</DropdownMenuGroup>
                  </DropdownMenuPopover>
                </DropdownMenu>
              )}
            </>
          }
        />
      )}
      <PaneContent>
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableHeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  let sortDirection: 'ascending' | 'descending' | undefined;
                  if (column.isSorted) {
                    if (column.isSortedDesc) {
                      sortDirection = 'descending';
                    } else {
                      sortDirection = 'ascending';
                    }
                  }

                  return (
                    <TableHeadCell
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      sortDirection={sortDirection}
                    >
                      {column.render('Header')}
                    </TableHeadCell>
                  );
                })}
                {isNavigableTable && <NavigableTablePlaceholderCell />}
              </TableHeadRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              tableInstance.prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <AutoTableRow
                  row={row}
                  {...rowProps}
                  linkProps={linkProps}
                  isRowSelected={isRowSelected}
                  onRowDeselect={onRowDeselect}
                />
              );
            })}
          </TableBody>
        </Table>
      </PaneContent>
      {rows.length === 0 && (
        <Alert heading={emptyStateHeading} copy={emptyStateCopy} relation="stacked" />
      )}
    </>
  );
}
